import React, { useState, useEffect, forwardRef, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Header.css';
import logo from '/assets/vector/logo.svg';
import HeaderOverlay from './HeaderOverlay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../context/AuthContext';

import gsap from 'gsap';

const Header = forwardRef(({ openSearch }, ref) => {
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [closeTimeout, setCloseTimeout] = useState(null);
  const { isAuthenticated, logout } = useAuth();
  const [shouldRenderDropdown, setShouldRenderDropdown] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    document.body.style.overflow = !menuOpen ? 'hidden' : 'auto';
  };

  const latestActiveDropdown = useRef(activeDropdown);

  useEffect(() => {
    latestActiveDropdown.current = activeDropdown;
  }, [activeDropdown]);


  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 200);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const navLinks = document.querySelectorAll('.overlay-nav a');
    if (menuOpen) {
      gsap.fromTo(navLinks, { y: -5, opacity: 0 }, {
        y: 0, opacity: 1, stagger: 0.05, duration: 0.4, ease: 'power1.out'
      });
    }
  }, [menuOpen]);





  useEffect(() => {
    if (activeDropdown) {
      setShouldRenderDropdown(true);
  
      const dropdownEl = document.querySelector('.dropdown-inner');
      if (dropdownEl) {
        gsap.fromTo(dropdownEl, { opacity: 0, y: 0 }, {
          opacity: 1,
          y: 0,
          duration: 0.6,
          ease: 'power2.out',
        });
      }
  
    } else if (shouldRenderDropdown) {
      const dropdownEl = document.querySelector('.dropdown-inner');
      if (dropdownEl) {
        gsap.to(dropdownEl, {
          opacity: 0,
          y: -30,
          duration: 0.5,
          ease: 'power2.in',
          onComplete: () => {
            // Alleen verwijderen als gebruiker niet weer snel teruggehovert is
            if (!latestActiveDropdown.current) {
              setShouldRenderDropdown(false);
            }
          }
        });
      } else {
        // Element is niet gevonden, dus gewoon dropdown sluiten
        setShouldRenderDropdown(false);
      }
    }
  }, [activeDropdown]);
  
  
  


  

  const handleDropdownEnter = (menu) => {
    if (closeTimeout) clearTimeout(closeTimeout);
    setActiveDropdown(menu);
  };

  const handleDropdownLeave = () => {
    const timeout = setTimeout(() => {
      setActiveDropdown(null);
    }, 100);
    setCloseTimeout(timeout);
  };

  const getBreadcrumb = () => {
    if (location.pathname.startsWith('/collecties/exclusief')) {
      const pathnames = location.pathname.split('/').filter((x) => x);
      const subPath = pathnames.slice(2).join(' / ');
      return (
        <div className="breadcrumb-exclusief-container">
          <div className="breadcrumb-container-inhoud">
            <div className="breadcrumb-exclusief">EXCLUSIEF</div>
            {subPath && <div className="breadcrumb-subpath">{subPath}</div>}
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <>

    <header ref={ref} className={`header ${isScrolled ? 'scrolled' : ''} ${menuOpen ? 'overlay-open' : ''}`}>
      <div className="header-content">
        <Link to="/" onClick={() => menuOpen && toggleMenu()}>
          <div className="logo">
            <img src={logo} alt="Thebalux Logo" />
          </div>
        </Link>

        <nav className={`nav-links ${menuOpen ? 'hidden' : ''}`}>
          <div
            className="dropdown"
            onMouseEnter={() => handleDropdownEnter('collecties')}
            onMouseLeave={handleDropdownLeave}
          >
            <Link to="/collecties" className="dropbtn">Collecties</Link>
          </div>

          <div
            className="dropdown"
            onMouseEnter={() => handleDropdownEnter('verkooppunten')}
            onMouseLeave={handleDropdownLeave}
          >
            <Link to="/verkooppunten" className="dropbtn">Uw verkooppunt</Link>
          </div>

          <div
            className="dropdown"
            onMouseEnter={() => handleDropdownEnter('service')}
            onMouseLeave={handleDropdownLeave}
          >
            <Link to="/service" className="dropbtn">Service</Link>
          </div>

          <div
            className="dropdown"
            onMouseEnter={() => handleDropdownEnter('contact')}
            onMouseLeave={handleDropdownLeave}
          >
            <Link to="/contact" className="dropbtn">Contact</Link>
          </div>
        </nav>

        <div className="header-icons">
          <button className="search-icon" onClick={openSearch}>
            <FontAwesomeIcon icon={faSearch} />
          </button>
          <div className={`burger-menu ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
            <div className="burger-icon">
              <div className="bar1"></div>
              <div className="bar2"></div>
              <div className="bar3"></div>
            </div>
          </div>
        </div>
      </div>







      <div
        className={`header-dropdown-wrapper ${activeDropdown === 'collecties' ? 'open' : ''}`}
        onMouseEnter={() => handleDropdownEnter('collecties')}
        onMouseLeave={handleDropdownLeave}
      >
          {shouldRenderDropdown && (
          <div className="dropdown-inner two-columns">

            <div className="column">
              <strong><h3>Nieuw</h3></strong>
              <Link to="/collecties/exclusief">Thebalux Exclusief</Link>
              <Link to="/collecties/spiegels">Luxe spiegels</Link>
            </div>

            <div className="column divider">
              <Link to="/collecties/exclusief"><strong><h3>Thebalux Exclusief</h3></strong></Link>
              <Link to="/collecties/exclusief/linea">Linea</Link>
              <Link to="/collecties/exclusief/epoque">Epoque</Link>
              <Link to="/collecties/exclusief/morrison">Morrison</Link>
              <Link to="/collecties/exclusief/tresore">Tresore</Link>
              <Link to="/collecties/exclusief/ligne">Ligne</Link>
              <Link to="/collecties/exclusief/modena">Modena</Link>
            </div>

            <div className="column divider">
              <strong><h3>Badkamer</h3></strong>
              <Link to="/collecties/beat40">Beat 40</Link>
              <Link to="/collecties/beat45">Beat 45</Link>
              <Link to="/collecties/nuance515">Nuance 51.5</Link>
              <Link to="/collecties/exclusief">Thebalux Exclusief</Link>
              <Link to="/collecties/type">Type</Link>
              <br/>
              <strong><h3>Toilet</h3></strong>
              <Link to="/collecties/sjithouse">Sjithouse Toiletfurniture</Link>
              <br/>
              <strong><h3>Kranen</h3></strong>
              <Link to="/collecties/thor">Thor badkamerkranen</Link>
            </div>

            <div className="column divider">
              <strong><h3>Overig</h3></strong>

              <Link to="/kleuren">Kleuren Overzicht</Link>
              <Link to="/downloads">Downloads</Link>
              <br/>

              <a href='/assets/bestanden/downloads/Thebalux_Brochure-2024.pdf'><button className='brochurebutton'>Bekijk onze brochure</button></a>

              <br/>
              {/* <Link to="/downloads"><strong><h3>Inspiratie</h3></strong></Link> */}


            </div>
          </div>
        )}
      </div>

      {/* Service */}
      <div
        className={`header-dropdown-wrapper ${activeDropdown === 'service' ? 'open' : ''}`}
        onMouseEnter={() => handleDropdownEnter('service')}
        onMouseLeave={handleDropdownLeave}
      >
          {shouldRenderDropdown && (
          <div className="dropdown-inner two-columns">

            <div className="column">
              <h3>Service</h3>
              <Link to="/veelgestelde-vragen">Veelgestelde vragen</Link>
              <Link to="/downloads">Downloads</Link>
              <br/>
              <a href="https://service.thebalux.nl">Service melden</a>

            </div>

            <div className="column divider">
              <Link to="/montage"><h3>Montage</h3></Link>

              <Link to="/montage/montagehandleiding">Montagehandleiding</Link>
              <Link to="/montage/aanvoermaten-afvoermaten">Aanvoerafvoermaten</Link>

            </div>

            <div className="column divider">

              <Link to="/montage/ladesystemen"><h3>Ladesystemen</h3></Link>
              <Link to="/montage/ladesystemen/hettich">Hettich</Link>
              <Link to="/montage/ladesystemen/scala">Scala</Link>
              <Link to="/montage/ladesystemen/exclusief">Thebalux Exclusief</Link>
              <Link to="/montage/ladesystemen/xp">Mepla XP</Link>
              <br/>
              <Link to="/montage/scharnieren"><h3>Scharnieren</h3></Link>
              <Link to="/montage/scharnieren/exclusief">Exclusief</Link>
              <Link to="/montage/scharnieren/grass">Grass</Link>
              <Link to="/montage/scharnieren/hettich">Hettich</Link>

            </div>


          </div>
        )}
      </div>

      {/* Verkooppunten */}
      <div
        className={`header-dropdown-wrapper ${activeDropdown === 'verkooppunten' ? 'open' : ''}`}
        onMouseEnter={() => handleDropdownEnter('verkooppunten')}
        onMouseLeave={handleDropdownLeave}
      >
        {shouldRenderDropdown && (
          <div className="dropdown-inner two-colums">

            <div className='column'>
            <Link to="/verkooppunten">Zoek verkooppunt</Link>
            <Link to="/showroom">Thebalux Experience Center</Link>
            </div>

            {/* <div className='column divider'>
              <h3>Land</h3>
            <Link to="/verkooppunten">Nederland</Link>
            <Link to="/verkooppunten">Duitsland</Link>
            <Link to="/verkooppunten">Belgie</Link>
            <Link to="/verkooppunten">Curacao</Link>

            </div> */}

          </div>
        )}
      </div>

      {/* Contact */}
      <div
        className={`header-dropdown-wrapper ${activeDropdown === 'contact' ? 'open' : ''}`}
        onMouseEnter={() => handleDropdownEnter('contact')}
        onMouseLeave={handleDropdownLeave}
      >
        {shouldRenderDropdown && (
          <div className="dropdown-inner">
            <Link to="/contact">Contact</Link>
            <Link to="/vacatures">Vacatures</Link>
            <Link to="/over-ons">Over ons</Link>

          </div>
        )}
      </div>

      {getBreadcrumb() && (
        <div className={`breadcrumb-container ${isScrolled ? 'scrolled' : ''}`}>
          <div className="breadcrumb-container-inhoud">
            {getBreadcrumb()}
          </div>
        </div>
      )}




    <HeaderOverlay isOpen={menuOpen} toggleMenu={toggleMenu} />
  </header>
      
    {activeDropdown && (
          <div
            className="dropdown-backdrop visible"
            onClick={() => setActiveDropdown(null)}
            style={{ pointerEvents: 'auto' }}
          />
        )}
  

</>

);
});

export default Header;
