import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import gsap from 'gsap';

const HeaderOverlay = ({ isOpen, toggleMenu }) => {
  useEffect(() => {
    const navLinks = document.querySelectorAll('.overlay-nav a');
    if (isOpen) {
      gsap.fromTo(
        navLinks,
        { y: -5, opacity: 0 },
        { y: 0, opacity: 1, stagger: 0.05, duration: 0.4, ease: 'power1.out' }
      );
    }
  }, [isOpen]);

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'auto';
    return () => (document.body.style.overflow = 'auto');
  }, [isOpen]);

  return (
    <div className={`overlay ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
      <div className="overlay-content" onClick={(e) => e.stopPropagation()}>
        <nav className="overlay-nav">
          <div className="BigLinks">
            <Link to="/" onClick={toggleMenu}><h3>Home</h3></Link>
            <Link to="/collecties" onClick={toggleMenu}><h3>Collecties</h3></Link>
            <Link to="/verkooppunten" onClick={toggleMenu}><h3>Uw verkooppunt</h3></Link>
            <Link to="/showroom" onClick={toggleMenu}><h3>Showroom</h3></Link>
            <Link to="/over-ons" onClick={toggleMenu}><h3>Over ons</h3></Link>
            <Link to="/service" onClick={toggleMenu}><h3>Service</h3></Link>
          </div>

          <div className="SmallLinks">
            <Link to="/kleuren" onClick={toggleMenu}>Kleuren</Link>
            <Link to="/veelgestelde-vragen" onClick={toggleMenu}>Veelgestelde vragen</Link>
            <Link to="/downloads" onClick={toggleMenu}>Downloads</Link>
            <Link to="/montage" onClick={toggleMenu}>Montage</Link>
            <Link to="/vacatures" onClick={toggleMenu}>Vacatures</Link>
            <Link to="/contact" onClick={toggleMenu}>Contact</Link>
          </div>
        </nav>

        <div className="overlay-image">
          <img
            src="/assets/img/main/beat45/20240305-Cube100NavarroAvia-CPC-CloseUpCabinet2.webp"
            alt="Slide 1"
            className="active"
          />
        </div>
      </div>

      <div className="bottom">
        <img src="/assets/vector/Beeldmerk(chatback).svg" width="20px" />
        <Link to="/privacy" onClick={toggleMenu}>Privacy</Link>
        <Link to="/duurzaamheid" onClick={toggleMenu}>Duurzaamheid</Link>
        <Link to="/vacatures" onClick={toggleMenu}>Vacatures</Link>
        <a target="_blank" href="https://imagebank.thebalux.nl" onClick={toggleMenu}>Imagebank</a>
      </div>
    </div>
  );
};

export default HeaderOverlay;
