import React from 'react';
import PageTitle from '../../components/PageTitle';
import Spacer from '../../components/Spacer';
import DownloadBar from '../../components/service/DownloadBar'
import ImageParallax from '../../components/home/ImageParallax'
import ImageHero from '../../components/collect/ImageHero'
import TextBanner from '../../components/home/TextBanner'
import PdfViewer from '../../components/brochures/pdfViewer';
import MontageCat from '../../components/montage/MontageCat'; 

import { Helmet } from 'react-helmet-async';

const PrijsLijst = () => {
  return (
    <div>


      <Helmet>
        <title>Thebalux | Prijslijst</title>
        <meta name="description" content="Download hier prijslijst van Thebalux badkamermeubelen." />
        <meta name="keywords" content="downloads, prijslijst, verkoopdocumentatie, montagehandleiding, Thebalux, badkamermeubelen, montagetekeningen, brochure, prijslijst" />
        <link rel="canonical" href="https://www.thebalux.nl/prijslijst" />
      </Helmet>

      <ImageHero 
        title="Prijzen" 
        subtitle="" 
        height="20vh" 
        paddingTop="150px"
        imageSrc="/assets/img/main/beat40/1.jfif" 
      />
     
      
      <PdfViewer
        imageSrc="/assets/img/brochures/prijslijst.png"
        title="Download de prijslijst van Thebalux"
        buttonText="Download PDF"
        pdfUrl="/assets/bestanden/downloads/Thebalux_VKHB_2025_Interactief_LR.pdf" // Provide the actual path to the PDF
      />


    <TextBanner
        heading="Bekijk meer downloads..."
        buttonText="Bekijk meer downloads..."
        buttonLink="/downloads"

    />
      

    </div>
  )};
;

export default PrijsLijst;
