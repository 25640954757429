import React, { useEffect, useRef } from 'react';
import './DurabilitySection.css';
import { Link } from 'react-router-dom';

import gsap from 'gsap';
import SplitText from 'gsap/SplitText';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger, SplitText);

const DurabilitySection = () => {
  const titleRef = useRef(null); // Ref voor de titel

  useEffect(() => {
    const splitTitle = new SplitText(titleRef.current, { type: "words" });
  
    // Scroll-triggered titel animatie
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: titleRef.current,
        start: "top 80%",
        end: "bottom 40%",
        scrub: true,
      },
    });
  
    splitTitle.words.forEach((word, i) => {
      tl.to(word, { color: "#369c36", duration: 0.5 }, i * 0.1);
    });
  
    // Scroll-triggered body achtergrond op knop
    const button = document.getElementById('32178');
  
    const scrollTrigger = ScrollTrigger.create({
      trigger: button,
      start: "top 60%",
      end: "top 30%",
      onEnter: () => {
        gsap.to(document.body, {
          duration: 1.5,
          '--body-bg': '#e6f4e6',
          ease: 'power2.out'
        });
      },
      onLeave: () => {
        gsap.to(document.body, {
          duration: 1.5,
          '--body-bg': 'rgb(249, 249, 249)',
          ease: 'power2.out'
        });
      },
      onLeaveBack: () => {
        gsap.to(document.body, {
          duration: 1.5,
          '--body-bg': 'rgb(249, 249, 249)',
          ease: 'power2.out'
        });
      },
      onEnterBack: () => {
        gsap.to(document.body, {
          duration: 1.5,
          '--body-bg': '#e6f4e6',
          ease: 'power2.out'
        });
      }
      
    });
  
    return () => {
      scrollTrigger.kill();
      splitTitle.revert();
      tl.kill();
    };
  }, []);
  
  
  
 

  return (
    
    <div className="container3">
      <div className="text-content3">
        {/* Verbind de ref aan het h2-element */}
        <h2 id="colorchangingtitle" ref={titleRef}>
          Ontdek hoe Thebalux zich inzet voor een groenere toekomst.
        </h2>
        <p id='32178'>
          Bij Thebalux geloven we dat duurzaamheid de basis vormt van kwaliteit. Onze toewijding wordt weerspiegeld
          in onze producten, die niet alleen functionaliteit bieden, maar ook met oog op duurzaamheid ontworpen worden.
          <br /><br />
          Kies voor Thebalux voor een ervaring van verfijnd vakmanschap en duurzaamheid.
        </p>

        <Link to='/duurzaamheid'>
          <button id="bttnnature">Meer over onze doelen</button>
        </Link>  

         
    </div>
      <div className="image-content3">
        <img src="../../assets/img/exclusief/exa/naturedevotion.webp" alt="" />
      </div>
    </div>
  );
};

export default DurabilitySection;
