import { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./TextJourney.css";

gsap.registerPlugin(ScrollTrigger);

const JourneySection = () => {
  const leftRef = useRef(null);
  const rightRef = useRef(null);

  useEffect(() => {
    gsap.fromTo(leftRef.current,
      { opacity: 0, x: -50 },
      {
        opacity: 1,
        x: 0,
        duration: 1,
        scrollTrigger: {
          trigger: leftRef.current,
          start: "top 80%",
        },
      }
    );

    gsap.fromTo(rightRef.current,
      { opacity: 0, x: 50 },
      {
        opacity: 1,
        x: 0,
        duration: 1,
        scrollTrigger: {
          trigger: rightRef.current,
          start: "top 80%",
        },
      }
    );
  }, []);

  return (
    <section className="journey-section">
    <div className="journey-content">
        <div className="journey-left" ref={leftRef}>
        <p className="label">Ervaar Thebalux</p>
        <h2>Badkamermeubelen<br />met karakter</h2>
        </div>
        <div className="journey-right" ref={rightRef}>
        <p>
            Thebalux ontwerpt en produceert hoogwaardige badkamermeubelen, gemaakt in eigen huis met aandacht voor detail, design en duurzaamheid. Ontdek ons volledige assortiment en ervaar zelf waarom vakmanschap het verschil maakt.
        </p>
        </div>
    </div>
    </section>

  );
};

export default JourneySection;
