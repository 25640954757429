import { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SplitText } from "gsap/SplitText";
import "./AnimationTextFull.css";

gsap.registerPlugin(ScrollTrigger, SplitText);

const AnimatedHero = () => {
  const textRef = useRef(null);

  useEffect(() => {
    const split = new SplitText(textRef.current, {
      type: "lines, chars",
      linesClass: "line",
      charsClass: "char",
    });
  
    // Animatie per regel (zin)
    gsap.fromTo(
      split.lines,
      {
        y: 100,
        opacity: 0.8,
      },
      {
        y: -50,
        opacity: 1,
        ease: "power2.out",
        stagger: 0.1,
        scrollTrigger: {
          trigger: ".hero-text",
          start: "top 80%",
          end: "top 30%",
          scrub: true,
        },
      }
    );
  
    // Animatie per letter (blur en fade)
    gsap.fromTo(
      split.chars,
      {
        filter: "blur(20px)",
        opacity: 0.2,
      },
      {
        filter: "blur(0px)",
        opacity: 1,
        ease: "none",
        stagger: 0.01,
        scrollTrigger: {
          trigger: ".hero-text",
          start: "top 90%",
          end: "top 40%",
          scrub: true,
        },
      }
    );
  
    return () => {
      split.revert();
    };
  }, []);
  

  return (
    <section className="animated-hero">
      <div className="video-overlay" />
      <div className="hero-text">
        <h1 ref={textRef}>
          <span>Kwaliteit die je elke ochtend voelt.</span><br />
          <span>Details die jouw badkamer uniek maken.</span><br />
          <span>Ontworpen met zorg, gemaakt voor rust.</span>
        </h1>
      </div>
    </section>
  );
};

export default AnimatedHero;
