import React, { useEffect, useState, useRef } from 'react';
import { gsap } from 'gsap';
import './SearchOverlay.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import pageData from '../../data/pages.json';
import faqData from '../../data/faqData.json'; // Import FAQ data
import vacatures from '../../data/vacatures.json';
import Fuse from 'fuse.js';

const SearchOverlay = ({ toggleSearchOverlay }) => {
  const [hasInput, setHasInput] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [isClosing, setIsClosing] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);
  const inputRef = useRef(null);
  const resultRefs = useRef([]);
  const navigate = useNavigate();
  const [isTyping, setIsTyping] = useState(false);

  useEffect(() => {
    const isMobileOrTablet = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      return /android|ipad|iphone|ipod/i.test(userAgent) || window.innerWidth <= 768;
    };
  
    if (!isMobileOrTablet()) {
      inputRef.current.focus(); // Focus only on desktop
    }
  
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  useEffect(() => {
    gsap.from('.search-content', {
      y: -50,
      opacity: 0,
      duration: 0.7,
      ease: 'power3.out',
    });
  }, []);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    setHasInput(value ? true : false);
    setActiveIndex(-1); // Reset active index when input changes
    setIsTyping(true);

    if (value) {
      gsap.to('.search-content .intro-text', {
        opacity: 0,
        y: -20,
        duration: 0.6,
        ease: 'power2.out',
      });
    } else {
      gsap.to('.search-content .intro-text', {
        opacity: 1,
        y: 0,
        duration: 0.6,
        ease: 'power2.inOut',
      });
    }
  };

  // Flatten the FAQ data to be searchable
  const flattenFAQData = faqData?.map((faq) => ({
    title: faq.question,
    description: faq.answer
      ? faq.answer
          .map((ans) => (Array.isArray(ans.content) ? ans.content.join(' ') : ans.content))
          .join(' ')
      : 'Geen beschrijving beschikbaar.',
    url: `/veelgestelde-vragen/${faq.slug}`,
    category: "Veelgestelde vragen",
  }));

  const fuseOptions = {
    keys: ['title', 'description', 'generalInfo', 'qualifications', 'benefits'],
    threshold: 0.3,
    includeMatches: true, // Include matched words
  };

  const combinedData = [
    ...pageData.pages.map((page) => ({
      title: page.title,
      description: page.description || 'Geen beschrijving beschikbaar.',
      url: page.url,
      category: page.category || 'Pages', // Label for pages
    })),
    ...flattenFAQData,
    ...vacatures.map((vacature) => ({
      title: vacature.title,
      description: vacature.description || vacature.generalInfo || 'Geen beschrijving beschikbaar.',
      url: `/vacatures/${vacature.slug}`,
      category: "Vacature",
    })),
  ];

  const fuse = new Fuse(combinedData, fuseOptions);
  const filteredResults = inputValue ? fuse.search(inputValue) : [];

  const highlightMatches = (description, matches, isFAQOrVacature) => {
    if (!isFAQOrVacature || !matches || !matches.length) return description;

    const matchRanges = matches.map((match) => match.indices).flat();

    let highlightedDescription = '';
    let currentPosition = 0;

    matchRanges.forEach(([start, end]) => {
      highlightedDescription += description.slice(currentPosition, start);
      highlightedDescription += `<strong>${description.slice(start, end + 1)}</strong>`;
      currentPosition = end + 1;
    });

    highlightedDescription += description.slice(currentPosition);

    // Trim the description for a cleaner UI, showing a max of 100 characters
    const maxLength = 100;
    if (highlightedDescription.length > maxLength) {
      return '...' + highlightedDescription.slice(0, maxLength) + '...';
    }
    return highlightedDescription;
  };

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowDown') {
      setActiveIndex((prevIndex) => {
        const newIndex = prevIndex < filteredResults.length - 1 ? prevIndex + 1 : prevIndex;
        scrollToResult(newIndex);
        return newIndex;
      });
    } else if (e.key === 'ArrowUp') {
      setActiveIndex((prevIndex) => {
        const newIndex = prevIndex > 0 ? prevIndex - 1 : 0;
        scrollToResult(newIndex);
        return newIndex;
      });
    } else if (e.key === 'Enter') {
      if (filteredResults.length === 1) {
        const result = filteredResults[0].item;
        navigate(result.url);
        handleClose();
      } else if (activeIndex >= 0 && activeIndex < filteredResults.length) {
        const result = filteredResults[activeIndex].item;
        navigate(result.url);
        handleClose();
      }
    }
  };

  const scrollToResult = (index) => {
    if (resultRefs.current[index]) {
      resultRefs.current[index].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  };

  useEffect(() => {
    if (hasInput && filteredResults.length && isTyping) {
      gsap.fromTo(
        '.search-result-item',
        { opacity: 0, y: 30 },
        { opacity: 1, y: 0, stagger: 0.1, duration: 0.6, ease: 'power2.out' }
      );
      setIsTyping(false);
    }
  }, [filteredResults, hasInput, isTyping]);

  const handleMouseEnter = (index) => {
    setActiveIndex(index);
  };

  const handleClose = () => {
    setIsClosing(true);
    gsap.to('.search-overlay', {
      opacity: 0,
      duration: 0.3,
      onComplete: () => toggleSearchOverlay(),
    });
  };

  return (
    <div className={`search-overlay ${isClosing ? 'closing' : ''}`}>
      <button className="close-button" onClick={handleClose}>
        <FontAwesomeIcon icon={faTimes} />
      </button>
      <div className="search-content">
        <h1>Zoek overal op deze site</h1>
        {!inputValue && <p className="intro-text">Vul een zoekterm in..</p>}
        <input
          type="text"
          className="search-input"
          placeholder="Type hier om te zoeken..."
          ref={inputRef}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
        />
        {hasInput && inputValue && (
          <div className="search-results-wrapper">
            <div className="search-results">
              {filteredResults.length > 0 ? (
                filteredResults.map((result, index) => {
                  const { item, matches } = result;
                  const isFAQOrVacature = item.category === "Veelgestelde vragen" || item.category === "Vacature";
                  const highlightedDescription = highlightMatches(item.description, matches, isFAQOrVacature);
                  
                  return (
                    <div
                      key={index}
                      className={`search-result-item ${activeIndex === index ? 'active' : ''}`}
                      onMouseEnter={() => handleMouseEnter(index)}
                      ref={(el) => (resultRefs.current[index] = el)} // Set ref for each result
                    >
                      <Link to={item.url} onClick={handleClose}>
                        <span className="category-label">{item.category}</span>
                        <h3>{item.title}</h3>
                        <p dangerouslySetInnerHTML={{ __html: highlightedDescription }} />
                      </Link>
                      <hr />
                    </div>
                  );
                })
              ) : (
                <p>Geen resultaten gevonden...</p>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchOverlay;
