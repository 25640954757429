import React, { useEffect, useRef, useState, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import LazyLoad from 'react-lazyload';
// import { WishlistProvider } from './context/WishlistContext';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';

import SearchOverlay from './components/zoeken/SearchOverlay'; // Importeer de SearchOverlay

import MainRoutes from './routes/MainRoutes';
import CollectieRoutes from './routes/CollectieRoutes';

import ServiceRoutes from './routes/ServiceRoutes';
import NotFound from './pages/404';
import Cookie from './components/Cookie';

import { getCookie } from './utils/CookieUtils';
import NieuwsBriefPopup from './components/nieuwsbrief/NieuwsBriefPopup';
import ScrollToTop from './components/ScrollToTop';
import './Transitions.css?v=1.0.0';
import './elements.css?v=1.0.0';

import { HelmetProvider } from 'react-helmet-async';
import ChatScreen from './components/chat/ChatScreen';
import { AuthProvider } from './context/AuthContext.jsx';
import LoadingPlaceholder from './components/LoadingPlaceholder';

import Banner from './components/home/Banner.jsx';
import ShareBanner from './components/algemeen/ShareBanner.jsx';
import ScrollSmoother from 'gsap/ScrollSmoother';


import gsap from 'gsap';

import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

function ScrollMemoryFix() {
  const location = useLocation();

  useEffect(() => {
    ScrollTrigger.clearScrollMemory();
    ScrollTrigger.refresh(true); // true = force update
  }, [location.pathname]);

  return null;
}

function AppContent() {
  const location = useLocation();
  const headerRef = useRef(null); 
  const themeRef = useRef('light');
  const [searchOpen, setSearchOpen] = useState(false); // Voeg state toe voor SearchOverlay
  const smootherRef = useRef(null); // Houd de instance bij

  const setTheme = (route) => {
    const theme = route?.theme || 'light';

    if (themeRef.current !== theme) {
      themeRef.current = theme;
      document.body.setAttribute('data-theme', theme);
    }

  };

  useEffect(() => {
    const currentRoute = allRoutes.find(route => location.pathname === route.path);
  
    setTheme(currentRoute);
  
    const handleScroll = () => {

      if (headerRef.current) {
        if (window.scrollY <= 200 && currentRoute?.header === 'herotrans') {
          headerRef.current.classList.add('herotrans');
        } else {
          headerRef.current.classList.remove('herotrans');
        }
      }
      
    };
   handleScroll();

    window.addEventListener('scroll', handleScroll);
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [location]);
  
  useEffect(() => {
    // ScrollSmoother initialiseren als nog niet gedaan
    if (!smootherRef.current) {
      smootherRef.current = ScrollSmoother.create({
        wrapper: "#smooth-wrapper",
        content: "#smooth-content",
        smooth: 1.0,
        effects: true,
      });
    } else {
      smootherRef.current.refresh();
    }

    return () => {
      if (smootherRef.current) {
        smootherRef.current.kill();
        smootherRef.current = null;
      }
    };
  }, []);

  
  const allRoutes = [...MainRoutes, ...CollectieRoutes, ...ServiceRoutes];

  return (
    <>
    <Header 
      ref={headerRef}
      openSearch={() => {
        setSearchOpen(true); // Zorg ervoor dat de overlay wordt geopend
      }} 
      />

    <NieuwsBriefPopup />
    <ScrollToTop />

    {/* Voeg SearchOverlay toe en beheer de zichtbaarheid */}
    {searchOpen && <SearchOverlay toggleSearchOverlay={() => setSearchOpen(false)} />}

    
    <div id="smooth-wrapper">
    <div id="smooth-content">
    

     {/* {location.pathname !== '/collecties/spiegelopmaat' && (
        <Header 
          ref={headerRef} 
          openSearch={() => {
            console.log(" Searchbutton clicked, opening overlay"); // Log voor debugging
            setSearchOpen(true); // Zorg ervoor dat de overlay wordt geopend
          }} 
        />
      )} */}

      {/* Logica ^ om header onzichtbaar te maken op spiegelopmaat */}

    
      

      <main style={{ position: 'relative' }}>
        <TransitionGroup component={null}>
          <CSSTransition
            key={location.pathname}
            classNames="page-transition"
            timeout={{ enter: 150, exit: 150 }}
            unmountOnExit
          >
            <div style={{ position: 'relative' }}>


             <Suspense fallback={<LoadingPlaceholder />}>
              <Routes location={location}>
                {allRoutes.map(({ path, element }, index) => (
                  <Route key={index} path={path} element={element} />
                ))}
                <Route path="*" element={<NotFound />} />
              </Routes>
            </Suspense>


            </div>
          </CSSTransition>
        </TransitionGroup>
      </main>

      <ShareBanner/>          
      <Footer />

      </div>
      </div>
      </>
  );
}

function App() {
  const adsRef = useRef(false); // Use ref to store ads-enabled state

  useEffect(() => {
    adsRef.current = getCookie('ads') === 'true';
    if (adsRef.current) {
      console.log("Advertentie-targeting ingeschakeld");
    }
  }, []);

  return (
    <AuthProvider>
    <HelmetProvider>
        {/* <ChatScreen/> */}
        <Router>
          <ScrollMemoryFix />
          <AppContent />
          <Cookie />
        </Router>
    </HelmetProvider>
    </AuthProvider>

  );
}

export default App;
